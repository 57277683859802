import { AbilityBuilder, MongoAbility, createMongoAbility } from "@casl/ability";
import { ViewContext } from "src/store/user-store";
import { siteContextPermissions } from "./site-context-permissions";
import { tenantContextPermissions } from "./tenant-context-permissions";
import { SubscriptionPlan } from "src/types/interfaces/subscription-plan";

type CRUD = "create" | "read" | "update" | "delete";
type SUBJECTS =
  | "Tenants"
  | "TenantsAdminInvitations"
  | "TenantResources"
  | "TenantBookingRules"
  | "BlackListedLicensePlate"
  | "TermsAndConditions"
  | "PrivacyPolicy"
  | "Statistics"
  | "Password"
  | "IntegrationQuestionnaire"
  | "SiteDetails"
  | "ParkingDetails"
  | "BookingRules"
  | "EmployeeStatistics"
  | "EmployeeReservations"
  | "EmployeeInvitations"
  | "EmployeeGroupBookingRules"
  | "EmployeeGroupReservations"
  | "ResourceGroups"
  | "Issues"
  | "GdprClause"
  | "OrganizationDetails"
  | "SpareSpots"
  | "TenantEvChargersPermissionAccess"
  | "SiteChargingRules"
  | "EmployeesChargingBookings";

type Abilities = [CRUD, SUBJECTS];

type ParkingActions =
  | CRUD
  | "assign"
  | "detach"
  | "addMap"
  | "deleteMap"
  | "lockSpot"
  | "setAsReserve"
  | "updatePriority"
  | "unlockSpot";
type ParkingAbilities = [ParkingActions, "ParkingSpots"];

type SitesActions = CRUD | "manageLimits";
type SitesAbilities = [SitesActions, "Sites"];

type ContactActions = CRUD | "send";
type ContactAbilities = [ContactActions, "Contact"];

interface SiteAdministrators {
  userId: number;
}

type SiteAdministratorsActions =
  | CRUD
  | "chooseRole"
  | "updatePhoneNumber"
  | "updateRole"
  | "resendInvitation";

type SiteAdministratorsAbilities = [
  SiteAdministratorsActions,
  "SiteAdministrators" | SiteAdministrators,
];

type ReservationActions = CRUD | "finish" | "cancel";
type ReservationAbilities = [ReservationActions, "Reservations"];

type EmployeesActions =
  | CRUD
  | "ban"
  | "changeGroup"
  | "changeLicensePlate"
  | "resendInvitation"
  | "downloadXls";

type EmployeesAbilities = [EmployeesActions, "Employees"];

type EmployeeGroupsActions =
  | CRUD
  | "downloadActiveSessions"
  | "readLatestBadgeStatusHistory"
  | "changeBookingRules"
  | "setAsDefault"
  | "removeAsDefault";
type EmployeeGroupsAbilities = [EmployeeGroupsActions, "EmployeeGroups"];

type EmployeeGroupResourceGroupsActions = CRUD | "add" | "remove";
type EmployeeGroupResourceGroupsAbilities = [
  EmployeeGroupResourceGroupsActions,
  "EmployeeGroupResourceGroups",
];

type EmployeeGroupEmployeesActions = CRUD | "add" | "remove";
type EmployeeGroupEmployeesAbilities = [EmployeeGroupEmployeesActions, "EmployeeGroupEmployees"];

type PlansActions =
  | "reserveLongerThan24hOn"
  | "reserveForMoreThan3DaysAheadOn"
  | "chooseRolesOtherThanOwnerOn"
  | "extendReservationByMoreThan24hOn"
  | "downloadStatisticsRaportOn"
  | "integrateWithAccessControlOn";
type PlansAbilities = [PlansActions, "subscriptionPlan"];

type ResourceGroupsResourcesActions = CRUD | "assignResource";
type ResourceGroupsResourcesAbilities = [ResourceGroupsResourcesActions, "ResourceGroupsResources"];

interface TenantAdministrators {
  userId: number;
}
type TenantAdministratorsActions =
  | CRUD
  | "chooseRole"
  | "updatePhoneNumber"
  | "updateRole"
  | "resendInvitation";

type TenantAdministratorsAbilities = [
  TenantAdministratorsActions,
  "TenantAdministrators" | TenantAdministrators,
];

type SiteInvitationsActions = CRUD | "acceptInvitation" | "rejectInvitation";
type SiteInvitationsAbilities = [SiteInvitationsActions, "SiteInvitations"];

type AllSitesBillingActions = CRUD | "changeSpotsLimit" | "requestIntegration";

type AllSitesBillingActionsAbilities = [AllSitesBillingActions, "AllSitesBilling"];

type BillingHistoryActions = CRUD | "downloadInvoice";

type BillingHistoryActionsAbilities = [BillingHistoryActions, "BillingHistory"];

type BillingDetailsActions = CRUD | "downloadInvoice";

type BillingDetailsActionsAbilities = [BillingDetailsActions, "BillingDetails"];

type BillingPlanActions = CRUD | "changeSpotsLimit" | "changeUsersLimit" | "requestIntegration";

type BillingPlanActionsAbilities = [BillingPlanActions, "BillingPlan"];

type BillingActions = CRUD | "changePlan";

type BillingAbilities = [BillingActions, "Billing"];

type SiteRemovalActions = CRUD | "acceptOrReject" | "cancel";
type SiteRemovalAbilities = [SiteRemovalActions, "SiteRemoval"];

type PermanentSpotsActions = CRUD | "cancel" | "share" | "createPermanentBooking";
type PermanentSpotsAbilities = [PermanentSpotsActions, "PermanentSpots"];

type OrganizationRemovalActions = CRUD | "acceptOrReject" | "cancel";
type OrganizationRemovalAbilities = [OrganizationRemovalActions, "OrganizationRemoval"];

type SharedSpotsActions = CRUD | "cancel";
type SharedSpotsAbilities = [SharedSpotsActions, "SharedSpots"];

type SiteChargingSpotsActions = CRUD | "manageMap" | "manageTenants";
type SiteChargingSpotsAbilities = [SiteChargingSpotsActions, "SiteChargingSpots"];

type SiteChargingUsageLimitActions = CRUD | "manageLimit";
type SiteChargingUsageLimitAbilities = [SiteChargingUsageLimitActions, "SiteChargingUsageLimits"];

type TenantEvChargersActions = CRUD | "assign" | "detach";
type TenantEvChargersAbilities = [TenantEvChargersActions, "TenantEvChargers"];

type TenantChargingSpotsActions = "read";
type TenantChargingSpotsAbilities = [TenantChargingSpotsActions, "TenantChargingSpots"];

type EmployeesChargingPermissionsActions = CRUD | "grantPermission" | "revokePermission";
type EmployeesChargingPermissionsAbilities = [
  EmployeesChargingPermissionsActions,
  "EmployeesChargingPermissions",
];

type EmployeesChargingPermissionRequestsActions = CRUD | "approve" | "decline";
type EmployeesChargingPermissionRequestsAbilities = [
  EmployeesChargingPermissionRequestsActions,
  "EmployeesChargingPermissionRequests",
];

export type AppAbility = MongoAbility<
  | Abilities
  | ParkingAbilities
  | SitesAbilities
  | SiteAdministratorsAbilities
  | ContactAbilities
  | ReservationAbilities
  | EmployeesAbilities
  | EmployeeGroupsAbilities
  | EmployeeGroupResourceGroupsAbilities
  | EmployeeGroupEmployeesAbilities
  | ResourceGroupsResourcesAbilities
  | PlansAbilities
  | TenantAdministratorsAbilities
  | SiteInvitationsAbilities
  | AllSitesBillingActionsAbilities
  | BillingHistoryActionsAbilities
  | BillingDetailsActionsAbilities
  | BillingPlanActionsAbilities
  | BillingHistoryActionsAbilities
  | BillingDetailsActionsAbilities
  | SiteRemovalAbilities
  | OrganizationRemovalAbilities
  | BillingAbilities
  | PermanentSpotsAbilities
  | SharedSpotsAbilities
  | SiteChargingSpotsAbilities
  | SiteChargingUsageLimitAbilities
  | TenantEvChargersAbilities
  | TenantChargingSpotsAbilities
  | EmployeesChargingPermissionsAbilities
  | EmployeesChargingPermissionRequestsAbilities
>;

export type Role = "owner" | "admin" | "readonly" | "master";

export function definePermissions(
  viewContext: ViewContext,
  role: Role,
  userId: number,
  shouldHaveAccessToSiteActionsInTenantContext: boolean,
  isLegacyAgreement: boolean,
  hasAccessControlIntegration: boolean,
  siteHasAccessToEVChargers: boolean,
  tenantHasAccessToEVChargers: boolean,
  organizationPlan?: SubscriptionPlan,
) {
  const builder = new AbilityBuilder<AppAbility>(createMongoAbility);

  switch (viewContext) {
    case "site":
      siteContextPermissions(builder, role, userId, isLegacyAgreement, siteHasAccessToEVChargers);

      break;

    case "tenant":
      tenantContextPermissions(
        builder,
        role,
        userId,
        shouldHaveAccessToSiteActionsInTenantContext,
        hasAccessControlIntegration,
        isLegacyAgreement,
        tenantHasAccessToEVChargers,
      );

      if (organizationPlan === "premium") {
        builder.can("chooseRolesOtherThanOwnerOn", "subscriptionPlan");
        builder.can("downloadStatisticsRaportOn", "subscriptionPlan");
        builder.can("reserveForMoreThan3DaysAheadOn", "subscriptionPlan");
        builder.can("reserveLongerThan24hOn", "subscriptionPlan");

        if (role === "owner" || role === "master") {
          builder.can("changeUsersLimit", "BillingPlan");
        }
      }

      break;

    default:
      break;
  }

  return builder.build();
}
