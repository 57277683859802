import { Box } from "@material-ui/core";
import { useEffect } from "react";
import { useLocation, useRoutes } from "react-router-dom";
import siteRoutes from "src/features/site/routes";
import commonRoutes from "./common-routes";
import tenantRoutes from "./features/tenant/routes";

import { observer } from "mobx-react-lite";

import { useTranslation } from "react-i18next";
import LaunchErrorOverlay from "./components/atoms/launch-error-overlay";
import Loader from "./components/atoms/loader/loader";
import AdminOrganizationsErrorContent from "./components/atoms/organization/admin-organizations-error-content";
import { OnboardingDialog } from "./components/organisms/onboarding/onboarding-dialog";
import {
  determineIfOnboardingDialogShouldBeOpen,
  determineOnboardingContextByRegistrationType,
} from "./components/organisms/onboarding/utils";
import { authRoutes } from "./constants/auth-routes";
import { useAuth } from "./contexts/auth-provider";
import { useSiteAdminsProfile } from "./features/site/hooks/api/admins/use-site-admins-profile";
import { useAdminOrganizations } from "./hooks/use-admin-organizations";
import { useDialog } from "./hooks/use-dialog";
import { useSetInitialOrganizations } from "./hooks/use-set-initial-organizations";
import { useSetViewContext } from "./hooks/use-set-view-context";
import useStore from "./hooks/use-store";
import { useUpdateLanguage } from "./hooks/use-update-language";
import { AbilityProvider } from "./permissions/can";
import { ViewContext } from "./store/user-store";
import { UserRole } from "./types/enum/user-role";
import { useSiteEvChargersModuleAccess } from "./features/site-ev-chargers/hooks/api/use-site-ev-chargers-module-access";
import { useTenantEvChargersModuleAccess } from "./features/tenant-ev-chargers/hooks/api/use-tenant-ev-chargers-module-access";

const getRoutesForViewContext = (currentViewContext: ViewContext) => {
  switch (currentViewContext) {
    case "site":
      return [...siteRoutes, ...commonRoutes];

    case "tenant":
      return [...tenantRoutes, ...commonRoutes];
  }
};

const App = () => {
  const { user, groups } = useStore();
  const location = useLocation();
  const { t } = useTranslation();

  const content = useRoutes(getRoutesForViewContext(user.viewContext));

  const auth = useAuth();

  const isLoggedIn = !!auth.session?.access_token;

  const shouldEnableOnboardingDialogCheck = !authRoutes.includes(location.pathname);

  const enableDataFetching = isLoggedIn && shouldEnableOnboardingDialogCheck;

  const [isOnboardingDialogOpen, handleOpenOnboardingDialog, handleCloseOnboardingDialog] =
    useDialog();

  const [{ data, isLoading: isLoadingAdminOrganizations, error: adminOrganizationsError }] =
    useAdminOrganizations(enableDataFetching);

  const {
    data: siteAdminData,
    isLoading: isLoadingSiteAdminProfile,
    error: siteAdminProfileError,
  } = useSiteAdminsProfile(enableDataFetching, false);

  //? TODO: disable billing data fetching because Salesforce throws errors because it is not paid
  // const { data: billingData } = useBillings(undefined, isLoggedIn && !!data);

  useSetViewContext();

  useSetInitialOrganizations(enableDataFetching);

  const { data: siteEvChargersModuleAccess } = useSiteEvChargersModuleAccess();
  const { data: tenantEvChargersModuleAccess } = useTenantEvChargersModuleAccess();

  useEffect(() => {
    if (
      determineIfOnboardingDialogShouldBeOpen(
        data,
        siteAdminData,
        shouldEnableOnboardingDialogCheck,
      )
    ) {
      handleOpenOnboardingDialog();
    }
  }, [data, siteAdminData, user.viewContext, location.pathname]);

  // TODO?: do we need this??
  //TODO REFACTOR FETCHING AND STORING GROUPS
  useEffect(() => {
    if (user.userOrganizationId && enableDataFetching) {
      groups.fetchGroups();
    }
  }, [isLoggedIn, enableDataFetching]);

  useUpdateLanguage();

  const renderContent = () => {
    if (
      auth.loading ||
      (enableDataFetching && user.isLoadingViewContextAccess) ||
      (enableDataFetching && isLoadingAdminOrganizations && siteAdminProfileError) ||
      (enableDataFetching && isLoadingSiteAdminProfile && adminOrganizationsError)
    ) {
      return (
        <Box
          sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
          <Loader />
        </Box>
      );
    }

    if (adminOrganizationsError && siteAdminProfileError && enableDataFetching) {
      return (
        <LaunchErrorOverlay
          title={t("siteManagement:siteAdmins.launchError.title")}
          trailingContentContainer={<AdminOrganizationsErrorContent />}
        />
      );
    }

    if (
      determineIfOnboardingDialogShouldBeOpen(
        data,
        siteAdminData,
        shouldEnableOnboardingDialogCheck,
      )
    ) {
      return (
        <OnboardingDialog
          isOpen={isOnboardingDialogOpen}
          onClose={handleCloseOnboardingDialog}
          role={determineOnboardingContextByRegistrationType(data, siteAdminData)}
        />
      );
    } else {
      return content;
    }
  };

  return (
    <AbilityProvider
      viewContext={user.viewContext}
      role={user.viewContext === "site" ? siteAdminData?.role : data?.role ?? UserRole.VIEWER}
      userId={user.viewContext === "site" ? siteAdminData?.id : data?.id ?? 0}
      organizationPlan={data?.actualOrganization.plan ?? "free"}
      shouldHaveAccessToSiteActionsInTenantContext={
        data?.actualOrganization?.shouldHaveAccessToSiteActionsInTenantContext
      }
      isLegacyAgreement={
        user.viewContext === "site"
          ? siteAdminData?.actualSite?.isLegacyAgreement
          : data?.actualOrganization?.isLegacyAgreement
      }
      //? TODO: disable billing data fetching because Salesforce throws errors because it is not paid
      // hasAccessControlIntegration={
      //   billingData?.licenseForParkingSpots?.hasAccessControlIntegration
      // }
      hasAccessControlIntegration={false}
      siteHasAccessToEVChargers={siteEvChargersModuleAccess?.hasAccessToEVChargers}
      tenantHasAccessToEVChargers={tenantEvChargersModuleAccess?.hasAccessToEVChargers}>
      {renderContent()}
    </AbilityProvider>
  );
};

export default observer(App);
