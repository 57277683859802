import { AbilityBuilder } from "@casl/ability";
import { AppAbility, Role } from "./define-permissions";

export const siteContextPermissions = (
  builder: AbilityBuilder<AppAbility>,
  role: Role,
  userId: number,
  isLegacyAgreement: boolean,
  hasAccessToEVChargers: boolean,
) => {
  const { can, cannot } = builder;

  can("read", "Tenants");

  can("read", "TenantResources");

  can("read", "TenantBookingRules");

  can("read", "TenantsAdminInvitations");

  can("read", "ParkingSpots");

  can("read", "Statistics");

  can("read", "Sites");

  can("read", "SiteAdministrators");

  can("read", "BlackListedLicensePlate");

  can("read", "TermsAndConditions");
  can("read", "PrivacyPolicy");
  can("update", "Password");

  can("read", "SiteDetails");
  can("read", "ParkingDetails");

  can("read", "SiteRemoval");

  if (hasAccessToEVChargers) {
    can("read", "SiteChargingSpots");
    can("read", "SiteChargingUsageLimits");

    can("read", "TenantEvChargers");

    can("read", "SiteChargingRules");
  }

  if (role === "admin" || role === "owner" || role === "master") {
    can("create", "Tenants");
    can("delete", "Tenants");
    can("update", "Tenants");

    can("create", "TenantsAdminInvitations");

    can("create", "ParkingSpots");
    can("update", "ParkingSpots");
    can("delete", "ParkingSpots");
    can("assign", "ParkingSpots");
    can("detach", "ParkingSpots");
    can("addMap", "ParkingSpots");
    can("setAsReserve", "ParkingSpots");
    can("deleteMap", "ParkingSpots");
    can("lockSpot", "ParkingSpots");
    can("unlockSpot", "ParkingSpots");

    can("create", "Sites");

    can("updatePhoneNumber", "SiteAdministrators", { userId: { $eq: userId } });
    can("resendInvitation", "SiteAdministrators", { userId: { $ne: userId } });

    can("create", "BlackListedLicensePlate");
    can("delete", "BlackListedLicensePlate");

    can("send", "Contact");
    can("update", "IntegrationQuestionnaire");

    if (hasAccessToEVChargers) {
      can("manageMap", "SiteChargingSpots");
      can("manageTenants", "SiteChargingSpots");

      can("manageLimit", "SiteChargingUsageLimits");

      can("assign", "TenantEvChargers");
      can("detach", "TenantEvChargers");

      can("update", "SiteChargingRules");
    }
  }

  if (role === "owner" || role === "master") {
    can("delete", "TenantsAdminInvitations");

    can("delete", "Sites");
    can("update", "Sites");
    can("manageLimits", "Sites");

    can("create", "SiteAdministrators");
    can("chooseRole", "SiteAdministrators");
    can("updatePhoneNumber", "SiteAdministrators");
    can("updateRole", "SiteAdministrators", { userId: { $ne: userId } });
    can("delete", "SiteAdministrators", { userId: { $ne: userId } });

    can("update", "SiteDetails");
    can("create", "ParkingDetails");

    can("cancel", "SiteRemoval");
    can("acceptOrReject", "SiteRemoval");

    can("read", "AllSitesBilling");
    can("requestIntegration", "AllSitesBilling");
    can("changeSpotsLimit", "AllSitesBilling");

    can("read", "BillingHistory");
    can("downloadInvoice", "BillingHistory");

    can("read", "BillingDetails");
    can("update", "BillingDetails");
  }

  if (isLegacyAgreement) {
    cannot("read", "AllSitesBilling");
    cannot("requestIntegration", "AllSitesBilling");
    cannot("changeSpotsLimit", "AllSitesBilling");

    cannot("read", "BillingHistory");
    cannot("downloadInvoice", "BillingHistory");

    cannot("read", "BillingDetails");
    cannot("update", "BillingDetails");
  }
};
