export const ChargingSpotsIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.0648 13.2036L18.0635 13.2047L10.0572 20.2045L10.0552 20.2063C9.49365 20.6929 8.66771 20.7356 8.06123 20.2943L18.0648 13.2036ZM18.0648 13.2036C18.5574 12.7704 18.7435 12.0688 18.4816 11.4369C18.2683 10.7962 17.6542 10.3999 17.0087 10.3999H14.4319L16.4778 5.63093C16.7732 4.94353 16.5526 4.14451 15.9474 3.70541L15.9474 3.70535C15.3421 3.26634 14.5168 3.30467 13.9534 3.79474L13.9523 3.79573L5.94696 10.795L5.94649 10.7955C5.44481 11.235 5.26782 11.9394 5.50227 12.5639L5.50283 12.5653C5.73401 13.1762 6.32408 13.5998 6.9727 13.5998H9.57763M18.0648 13.2036L9.57763 13.5998M9.57763 13.5998L7.53148 18.3688C7.53147 18.3689 7.53145 18.3689 7.53143 18.369C7.23614 19.0554 7.45717 19.8549 8.06104 20.2942L9.57763 13.5998Z"
      stroke="currentColor"
      stroke-width="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
