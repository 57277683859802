import bookings from "src/features/bookings/locales/en";
import employeesChargingBookings from "src/features/employees-charging-bookings/locales/en";
import employeesChargingPermissionAccess from "src/features/employees-charging-permission-access/locales/en";
import employeesChargingPermissionRequests from "src/features/employees-charging-permission-requests/locales/en";
import employeesChargingPermissions from "src/features/employees-charging-permissions/locales/en";
import evChargers from "src/features/ev-chargers/locales/en";
import siteEvChargersElectricityUsage from "src/features/site-ev-chargers-electricity-usage/locales/en";
import siteEvChargersRules from "src/features/site-ev-chargers-rules/locales/en";
import siteEvChargersUsageLimits from "src/features/site-ev-chargers-usage-limits/locales/en";
import siteEvChargers from "src/features/site-ev-chargers/locales/en";
import siteEvChargingSpots from "src/features/site-ev-charging-spots/locales/en";
import tenantEvChargingSpots from "src/features/tenant-ev-charging-spots/locales/en";
import assignEvChargers from "./assign-ev-chargers.json";
import auth from "./auth.json";
import billing from "./billing.json";
import blacklist from "./blacklist.json";
import changePassword from "./change-password.json";
import common from "./common.json";
import contactDetails from "./contact-details.json";
import contactForm from "./contact-form.json";
import createPermanentSession from "./create-permanent-session.json";
import createReservation from "./create-reservation.json";
import createRuleGroup from "./create-rule-group.json";
import diagram from "./diagram.json";
import employee from "./employee.json";
import employeesGroupDetails from "./employees-group-details.json";
import employeesGroups from "./employees-groups.json";
import employees from "./employees.json";
import invitations from "./invitations.json";
import issueDetails from "./issue-details.json";
import issues from "./issues.json";
import onboarding from "./onboarding.json";
import organizationAdmins from "./organization-admins.json";
import organizationDetails from "./organization-details.json";
import reservation from "./reservation.json";
import reservations from "./reservations.json";
import resourceGroupDetails from "./resource-group-details.json";
import resources from "./resources.json";
import routes from "./routes.json";
import ruleGroups from "./rule-groups.json";
import settings from "./settings.json";
import siteManagement from "./site-management.json";
import sites from "./sites.json";
import statistics from "./statistics.json";
import supabase from "./supabase.json";
import tenantDetails from "./tenant-details.json";
import tenants from "./tenants.json";
import validation from "./validation.json";

export default {
  common,
  reservations,
  reservation,
  createReservation,
  statistics,
  blacklist,
  contactDetails,
  contactForm,
  createPermanentSession,
  createRuleGroup,
  diagram,
  employee,
  employees,
  employeesGroupDetails,
  employeesGroups,
  invitations,
  issues,
  issueDetails,
  organizationDetails,
  resourceGroupDetails,
  resources,
  organizationAdmins,
  ruleGroups,
  sites,
  validation,
  settings,
  changePassword,
  onboarding,
  tenants,
  tenantDetails,
  siteManagement,
  routes,
  billing,
  auth,
  evChargers,
  assignEvChargers,
  tenantEvChargingSpots,
  employeesChargingPermissions,
  employeesChargingPermissionAccess,
  employeesChargingPermissionRequests,
  siteEvChargingSpots,
  siteEvChargersUsageLimits,
  siteEvChargersElectricityUsage,
  siteEvChargersRules,
  siteEvChargers,
  bookings,
  employeesChargingBookings,
  supabase,
};
